'use strict';

/* eslint-disable no-unused-vars */

/*
 * Global Right Menu
 * globalRightMenuMobilePhone(globalData.rightMenus)
 */
export function globalRightMenuMobilePhone(data) {
  if (!data || !Array.isArray(data.rightMenus)) {
    return;
  }
  const rightMenus = data.rightMenus;
  for (const menu of rightMenus) {
    if (menu && menu.url) {
      return menu.url;
    }
  }
}
