'use strict';

import { ifBrowserThen } from 'acq-utils/lib/Browser';
const appLoadingClassName = 'app-loading';

export const removeAppLoadingState = ifBrowserThen(() => {
  const classListObj = document.body.classList;
  if (classListObj.contains(appLoadingClassName)) {
    classListObj.remove(appLoadingClassName);
  }
});
