'use strict';

import get from 'lodash.get';
import console from 'core_modules/utils/log';
import { isServer } from 'src/universal/utils/browser-detection/index';
import { isBrowser } from 'acq-utils/lib/misc';
import {
  SEGMENT_TYPES,
  getDcfCodeByRawCookie
} from 'service_modules/dcf-services/dcf-segment-operations';
import { DCF_SETTINGS } from 'service_modules/dcf-services/dcf-detection';
import { getCookieItem } from 'service_modules/utils/window-cookies';
import { ensureAdobeEventsAreReady } from 'src/universal/utils/thirdPartyScriptHelpers/ensureAdobeHasLoaded';
import isObjectEmpty from 'src/universal/utils/isObjectEmpty';
import rateLimit from 'src/universal/utils/rateLimit';

const generateDTMEventInfo = dtmTag => {
  if (isServer() || !window.digitalData) {
    return;
  }

  return {
    eventInfo: {
      eventAction: dtmTag.eventAction,
      eventAsset: dtmTag.eventAsset,
      eventType: dtmTag.eventType || 'pageInteraction'
    }
  };
};

const generateDTMEventProductInfo = ({ pmc = '' }) => {
  if (isServer() || !window.digitalData) {
    return;
  }

  if (window.digitalData.productInfo) {
    window.digitalData.productInfo = [];
  }

  return {
    productName: ';' + pmc,
    pmc: pmc.split(':')[2]
  };
};

const updateDigitalDataWithSingleEvent = event => {
  window.digitalData.event = [event];
};

const updateDigitalDataWithMultipleEvents = events => {
  window.digitalData.event = [];
  events.forEach(dtmEvent => {
    if (!isObjectEmpty(dtmEvent)) {
      window.digitalData.event.push(generateDTMEventInfo(dtmEvent));
    }
  });
};

const updateDigitalDataWithPageLoadEvent = payload => {
  const { legacyTag, dtmTag } = payload;
  window.digitalData.event = [];
  if (
    legacyTag &&
    legacyTag.subCategory2 &&
    legacyTag.subCategory2 === 'CardDetail'
  ) {
    const productInfo = generateDTMEventProductInfo(dtmTag);

    const eventObject = {
      eventInfo: {
        eventType: 'cardApplication',
        eventAction: 'prodView'
      },
      productInfo: [productInfo]
    };

    if (!window.digitalData.productInfo || !window.digitalData.productInfo.length) {
      window.digitalData.productInfo = [productInfo];
    }

    window.digitalData.event.push(eventObject);
  }

  if (dtmTag) {
    window.digitalData.event.push(dtmTag);
  }
};

const dispatchDataToAdobe = rateLimit(async (name, type, payload) => {
  try {
    await ensureAdobeEventsAreReady();
    if (type === 'dtmEvent') {
      updateDigitalDataWithSingleEvent(payload);
    }
    if (type === 'triggerMultipleDtmEvents') {
      updateDigitalDataWithMultipleEvents(payload);
    }
    if (type === 'dtmPageLoad') {
      updateDigitalDataWithPageLoadEvent(payload);
    }
    window._satellite.track(name);
    console.log('%cdtmEvent fired: ', 'color: DarkViolet', {
      'window.digitalData': JSON.parse(JSON.stringify(window.digitalData))
    });
  } catch (error) {
    console.warn(`${type} fired with error: `, error);
  }
}, 1000);

export default class DtmTaggingModel {
  triggerDTMEvent = (eventData, isCustom) => {
    if (
      isServer() ||
      !window.digitalData ||
      !eventData ||
      !eventData.dtmTag ||
      eventData.dtmTag.dtmIgnore
    ) {
      return;
    }

    const dtmEvent = isCustom
      ? {
          eventInfo: {
            ...(get(eventData, 'dtmTag') || {})
          },
          productInfo: get(eventData, 'productInfo') || undefined
        }
      : generateDTMEventInfo(eventData.dtmTag);

    dispatchDataToAdobe('event', 'dtmEvent', dtmEvent);
  };

  triggerMultipleDTMEvents = (dtmList = []) => {
    if (isBrowser() && window.digitalData && dtmList.length) {
      dispatchDataToAdobe('event', 'triggerMultipleDtmEvents', dtmList);
    }
  };

  triggerDTMPageLoad = ({ legacyTag, dtmTag }) => {
    if (isServer() || !window.digitalData) {
      return;
    }

    this.updateDTMAnalytics();

    dispatchDataToAdobe('page', 'dtmPageLoad', { legacyTag, dtmTag });
  };

  updateDTMPageData(pageData) {
    if (isServer() || !window.digitalData || !pageData || !pageData.dtmTag) {
      return;
    }

    // the digitalData variables below are spec'd in Adobe DTM
    // documents re: Canada, which show a version number of 2.4:
    // Kodama / Adobe Reports & Analytics Solution Design Document (SDD) v4

    const isString = variable => typeof variable === 'string';

    const pageInfoKeys = ['country', 'language', 'pageName'];

    pageInfoKeys.forEach(key => {
      const value = pageData.dtmTag[key];
      if (isString(value)) {
        window.digitalData.page.pageInfo[key] = value;
      }
    });

    const categoryKeys = [
      'businessUnit',
      'primaryCategory',
      'subCategory1',
      'subCategory2',
      'subCategory3'
    ];

    categoryKeys.forEach(key => {
      const value = pageData.dtmTag[key];
      if (isString(value)) {
        window.digitalData.page.category[key] = value;
      }
    });

    if (pageData.dtmTag.isInternational) {
      this.setABTestVariable();
    }

    this.updateDTMPageName(pageData.dtmTag);
  }

  setABTestVariable() {
    const page = window.digitalData && window.digitalData.page;
    const attributes = page && page.attributes;
    const abTestNameIsString = window.omn && typeof window.omn.abtest === 'string';
    if (abTestNameIsString && attributes) {
      // Puts the value of omn.abtest where the omniture tags will look for that value when they fire.
      attributes.abTest = window.omn.abtest;
    }
  }

  updateDTMPageName = dtmTag => {
    if (!isServer() && window.digitalData && dtmTag.pageName) {
      window.digitalData.page.pageInfo.pageName = dtmTag.pageName;
    }
  };

  shortCircuitAppendLinkQuery(baseURL, dtmTag) {
    return (
      !dtmTag ||
      !dtmTag.tag ||
      !dtmTag.type ||
      !baseURL ||
      isServer() ||
      !window.digitalData ||
      baseURL.includes('intlink') ||
      baseURL.includes('linknav')
    );
  }

  appendLinkQuery(baseURL, dtmTag, basePrefix) {
    if (this.shortCircuitAppendLinkQuery(baseURL, dtmTag)) {
      return baseURL;
    }

    const { tag, type, productName } = dtmTag;
    const locale = window.location.pathname.split('/')[1];
    const localeForPrefix = (locale.length === 2 && locale.toUpperCase()) || 'US';
    const OPEN_PREFIX =
      basePrefix ||
      `${localeForPrefix}-${window.digitalData.page.category.primaryCategory}-${
        window.digitalData.page.category.subCategory1
      }`;
    let baseLinkQuery = OPEN_PREFIX;

    // use dtm.options in the case that certain dynamic values are not set in time.
    const appendOptionByKey = (originalVal, key) => {
      const targetVal = get(dtmTag, `options.${key}`);
      return targetVal ? `${originalVal}-${targetVal}` : originalVal;
    };

    if (window.digitalData.page.category.subCategory2) {
      baseLinkQuery = `${baseLinkQuery}-${
        window.digitalData.page.category.subCategory2
      }`;
    } else {
      baseLinkQuery = appendOptionByKey(baseLinkQuery, 'subCategory2');
    }

    if (window.digitalData.page.category.subCategory3) {
      baseLinkQuery = `${baseLinkQuery}-${
        window.digitalData.page.category.subCategory3
      }`;
    } else {
      baseLinkQuery = appendOptionByKey(baseLinkQuery, 'subCategory3');
    }

    if (window.digitalData.page.pageInfo.pageName) {
      baseLinkQuery = `${baseLinkQuery}-${
        window.digitalData.page.pageInfo.pageName
      }-`;
    } else {
      baseLinkQuery = appendOptionByKey(baseLinkQuery, 'pageName');
    }

    if (!baseLinkQuery.endsWith('-')) {
      baseLinkQuery = `${baseLinkQuery}-`;
    }

    const tagVal = productName ? `${tag}-${productName}` : tag;

    return baseURL.includes('?')
      ? `${baseURL}&${type}=${baseLinkQuery}${tagVal}`
      : `${baseURL}?${type}=${baseLinkQuery}${tagVal}`;
  }

  updateDTMAnalytics() {
    this.updateDcfSegmentData();
    this.updateDnBVisitorData();
  }

  updateDcfSegmentData() {
    let cookie = getCookieItem(DCF_SETTINGS.COOKIE_KEY);
    if (!cookie) {
      return;
    }

    const SEGMENT_DESCRIPTION = {
      LARGE_BUSINESS: 'LargeBusinessSegment',
      SMALL_BUSINESS: 'SmallBusinessSegment'
    };
    let userSegment = getDcfCodeByRawCookie(cookie);
    let attachedSegmentDescription = '';

    if (userSegment === SEGMENT_TYPES.LARGE_BUSINESS) {
      attachedSegmentDescription = SEGMENT_DESCRIPTION.LARGE_BUSINESS;
    } else if (userSegment === SEGMENT_TYPES.SMALL_BUSINESS) {
      attachedSegmentDescription = SEGMENT_DESCRIPTION.SMALL_BUSINESS;
    } else {
      return;
    }

    const PAGE_CATEGORY = {
      SMALL_BUSINESS_VAC: `BusinessCards:${attachedSegmentDescription}`,
      SMALL_BUSINESS_PDP: `Open:CardDetail:${attachedSegmentDescription}`,
      CORPORATE_VAC: `CorporateCards:${attachedSegmentDescription}`,
      CORPORATE_PDP: `CorporateCards:CorporateCardDetail:${attachedSegmentDescription}`
    };

    const { page } = window.digitalData;

    if (
      page.pageInfo.pageName === 'ViewAllCards' &&
      page.category.subCategory2 === 'CorporateCards'
    ) {
      window.digitalData.page.attributes.tool = PAGE_CATEGORY.CORPORATE_VAC;
    } else if (
      page.pageInfo.pageName === 'ViewAllCards' &&
      page.category.subCategory2 === 'BusinessCards'
    ) {
      window.digitalData.page.attributes.tool = PAGE_CATEGORY.SMALL_BUSINESS_VAC;
    } else if (page.category.subCategory3 === 'CorporateCardDetail') {
      window.digitalData.page.attributes.tool = PAGE_CATEGORY.CORPORATE_PDP;
    } else if (page.category.subCategory3 === 'CardDetail') {
      window.digitalData.page.attributes.tool = PAGE_CATEGORY.SMALL_BUSINESS_PDP;
    }
  }

  updateDnBVisitorData() {
    let visitorData;
    try {
      visitorData = window.sessionStorage.getItem('gcs_dnb_Data');
    } catch (e) {
      console.error('error accessing storage', e);
    }

    if (!visitorData) {
      return;
    }

    try {
      const { duns, networth, industryNaics } = JSON.parse(visitorData);
      window.digitalData.page.attributes.dnsVisitorData = `${duns}|${networth}|${industryNaics}`;
    } catch (error) {
      console.warn(error);
      return;
    }
  }
}
