import browserStorage from 'acq-utils/lib/BrowserStorage';
import { STORAGE } from 'acq-utils/lib/enums';
import get from 'lodash.get';

const isClient = typeof window !== 'undefined';

const routePathTrack = {
  prevPath: undefined,
  currPath: undefined
};

// storage keys
const sessionKey = 'search-engine-referrer';
const storage = browserStorage(STORAGE.session).bindItem(sessionKey);

const getPreviousTransitionUrl = () => {
  return routePathTrack.prevPath || document.referrer || '';
};

const getCurrentTransitionUrl = () => {
  return routePathTrack.currPath || '';
};

// public methods
function getVisitSearchEngine() {
  return isClient && storage.get();
}

function setVisitSearchEngine() {
  if (!isClient) {
    return;
  }

  if (!storage.get()) {
    storage.set(isClient ? document.referrer || getCurrentTransitionUrl() : '');
  }
}

function getReferringUrl() {
  return isClient ? getPreviousTransitionUrl() : '';
}

function setRoutePath(location) {
  if (!isClient) {
    return;
  }

  routePathTrack.prevPath = routePathTrack.currPath || document.referrer;
  routePathTrack.currPath = location.href;
}

const getNavigatorProps = () => {
  if (!isClient) {
    return {};
  }

  const getPlugins = () => {
    const pluginObj = get(window, 'navigator.plugins') || [];
    const arrTmp = [];
    for (let i = 0; i < pluginObj.length; i++) {
      arrTmp.push(pluginObj[i].name || '');
    }

    return arrTmp.join(';');
  };

  return {
    maxTouchPoints: get(window, 'navigator.maxTouchPoints') || 0,
    hardwareConcurrency: get(window, 'navigator.hardwareConcurrency') || 0,
    deviceMemory: get(window, 'navigator.deviceMemory') || 0,
    plugins: getPlugins()
  };
};

export {
  getVisitSearchEngine,
  setVisitSearchEngine,
  getReferringUrl,
  setRoutePath,
  getNavigatorProps
};
