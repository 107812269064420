import isProduction from 'src/universal/utils/isProduction';

function isScriptLoaded(url) {
  const scripts = [...document.getElementsByTagName('script')];
  return scripts.some(({ src }) => src === url);
}

export function clickStreamHandler() {
  if (typeof window === 'undefined') {
    return;
  }
  window.digitalData.page.pageInfo.referringURL = document.referrer;
  const clickStreamScript = document.createElement('script');
  clickStreamScript.type = 'text/javascript';
  clickStreamScript.async = '';
  const scriptHost = isProduction()
    ? 'https://icm.aexp-static.com/Internet/US/DARE/ClickStreamVars.js'
    : 'https://qicm.aexp-static.com/Internet/US/DARE/ClickStreamVars.js';
  clickStreamScript.src = scriptHost;
  let isLoaded = isScriptLoaded(scriptHost);
  if (document && !isLoaded) {
    if (performance.timing.loadEventEnd) {
      document.body.appendChild(clickStreamScript);
    } else {
      window.addEventListener('load', function() {
        document.body.appendChild(clickStreamScript);
      });
    }
  } else if (isLoaded) {
    if (
      typeof window !== 'undefined' &&
      typeof window.ClickStreamVars !== 'undefined'
    ) {
      new ClickStreamVars().collect_clickstream_data(); // eslint-disable-line no-undef
    }
  }
}
