import queryString from 'qs';

import { getCookieItem } from 'service_modules/utils/window-cookies.js';

export default function getEloquaCookie() {
  const eloquaCookie = getCookieItem('ELOQUA');
  if (eloquaCookie) {
    const parsedCookie = queryString.parse(`?${eloquaCookie}`);
    return parsedCookie && parsedCookie.GUID ? parsedCookie.GUID : '';
  }
  return '';
}
