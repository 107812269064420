'use strict';

/*
  Removes HTML tags with their contents from a string.
  I.e., 'Apply for Blue Business <sup>SM</sup> Plus <br> Credit Card <img />' ==> 'Apply for Blue Business Plus Credit Card'
*/
export default function removeTagsAndTheirContent(text) {
  if (typeof text !== 'string' || !text) {
    return text;
  }

  return text
    .replace(/<([A-Z][A-Z0-9]*)\b[^>]*>(.*?)<\/\1>/gi, '')
    .replace(/<([A-Z][A-Z0-9]*)\b[^>]*>/gi, '');
}

export function removeTagsAndKeepContent(text) {
  if (typeof text !== 'string' || !text) {
    return text;
  }
  return text.replace(/<\/?[\w\s]*>|<.+[\W]>/g, '');
}
