'use strict';

import textRemoveTags from 'service_modules/utils/text-remove-tags';
import get from 'lodash.get';
import { clickStreamHandler } from './clickstream';

export const removeIntLinkImp = () => {
  if (window && window.digitalData.page.attributes.intlinkimp) {
    delete window.digitalData.page.attributes.intlinkimp;
  }
};

export function dtmDependentScripts() {
  try {
    clickStreamHandler();
  } catch (e) {
    console.error(e);
  }
}

export const getSpecialOffersEventAsset = ({ offerList = [], cardList = [] }) =>
  offerList.reduce((acc, offer = {}) => {
    // on pdps we need to account for special offers returned for the similar cards cross sell
    const cardListCopy = cardList.slice(0);
    cardListCopy.length === 1 &&
      Array.isArray(get(cardListCopy, '[0].similarCards')) &&
      cardListCopy.push(...cardListCopy[0].similarCards);
    if (offer.specialIndicator === 'Y') {
      const { shorterName } =
        cardListCopy.find((card = {}) => card.pmcCode === offer.pmcCode) || {};
      if (acc) {
        return `${acc}-${shorterName}`;
      } else {
        return `PZNOffer-${shorterName}`;
      }
    } else {
      return acc;
    }
  }, '');

export const buildPznProductInfo = ({
  offerList,
  cardList,
  lineOfBusiness,
  nonCardProducts
}) => {
  offerList = offerList.reduce((accumulator, offer) => {
    if (offer && !offer.defaultOffer && !offer.offSiteEEP) {
      /*
      Notes: 2 conditions must be met before including the card:
      (1) ASPEN must give us data (defaultOffer===true means no data from ASPEN).
      (2) It must not be an offSiteEEP.
      */
      const cardRef = cardList.find(card => card.pmcCode === offer.pmcCode) || {};
      const payload = {
        productName: textRemoveTags(cardRef.cardHeadline),
        pmc: offer.pmcCode,
        lineOfBusiness: lineOfBusiness || 'commercial',
        sourceCode: offer.sourcecode || '',
        position: offer.order
      };

      // GACC-6249 ask from analytics was to only add `richOfferFlag` key if product has special offer
      if (offer.specialIndicator === 'Y') {
        payload.richOfferFlag = true;
      }
      accumulator.push(payload);
    }
    return accumulator;
  }, []);
  nonCardProducts = (nonCardProducts || []).map(product => ({
    productName: product.shortName,
    lineOfBusiness: lineOfBusiness || 'commercial',
    pmc: product.pmc,
    position: product.order,
    sourceCode: product.sourcecode || ''
  }));
  return [...offerList, ...nonCardProducts];
};
