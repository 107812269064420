import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import { subNavFields } from '../../xml-http-requests/graphql/queries/fragments/global';
import { getAPICountryCode } from 'page_modules/http-errors/utils.js';

const GLOBAL_HEADER = `
globalHeaderPage: globalHeader {
    subNav {
      content {
        ${subNavFields}
        content {
          ${subNavFields}
          content {
            ${subNavFields}
            content {
              ${subNavFields}
            }
          }
        }
      }
    }
  }`;

const globalQuery = `query ($country: String!, $shop: String!, $page: String!) {
  cardShop(country: $country, shop: $shop, page: $page) {
    global {
      ${GLOBAL_HEADER}
    }
  }
}`;

const getQuery = () => globalQuery;

const getSubNavQuery = cc => ({
  query: getQuery(cc),
  variables: {
    country: getAPICountryCode(cc),
    shop: 'business',
    page: 'error-page'
  }
});

export const getSubnavData = params => {
  return ajax.graphQLPost(getSubNavQuery(params.countryCode), {
    'set-cookie': params['set-cookie'],
    queryName: 'ERROR_PAGE_QUERY',
    skipParser: true
  });
};
