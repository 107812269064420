import browserStorage from 'acq-utils/lib/BrowserStorage';
import { STORAGE } from 'acq-utils/lib/enums';

const DISMISSAL_SESSION_KEY = 'banner-dismissal';
const STORAGE_VAL = 'dismissed';

const storage = browserStorage(STORAGE.session);

export const recordUserBannerDismissal = () => {
  storage.set(DISMISSAL_SESSION_KEY, STORAGE_VAL);
};

export const hasUserDismissedBanner = () => {
  const val = storage.get(DISMISSAL_SESSION_KEY);
  return String(val) === STORAGE_VAL;
};
