import Cookies from 'js-cookie';
import { isServer } from 'src/universal/utils/browser-detection/index';

export function getCookieItem(cname) {
  if (isServer()) {
    return '';
  }

  return Cookies.get(cname);
}

export function setCookieItem(cname, cvalue, exdays, domain) {
  if (isServer()) {
    return;
  }

  const options = {};

  options.expires = exdays;
  if (domain) {
    options.domain = domain;
  }

  console.log(' cookie options: ', options);
  Cookies.set(cname, cvalue, options);
}

export function removeCookie(cname) {
  Cookies.remove(cname);
}

export const exposeToGlobal = () => {
  if (isServer()) {
    return;
  }

  window.setCookieItem = setCookieItem;
  window.getCookieItem = getCookieItem;
};
