const phoneNumberField = `
  phoneNumberArray {
    pageId
    data {
      title
      url
    }
  }
`;

export const subNavFields = `
  type
  imageName
  imageNames {
    mobile
    desktop
  }
  imageUrl {
    mobile
    desktop
  }
  header
  tabletHeader
  mobileHeader
  imgAlt
  url
  dtmTag {
    type
    tag
    pageName
    productName
    eventType
    eventAction
    eventAsset
  }
  expandDtm {
    eventAction
    eventAsset
    eventType
  }
  dtm {
    type
    tag
    pageName
    productName
    eventType
    eventAction
    eventAsset
  }
  ${phoneNumberField}
  title
  description
`;
