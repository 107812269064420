'use strict';
import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import getEloquaCookie from 'service_modules/eloqua';
import getQueryParams from 'src/universal/utils/getQueryParams';
import get from 'lodash.get';
import { getBotTrapFieldMap } from 'src/universal/components/BotTrap';

function postLeadGen(data, analyticsQueryParams) {
  const updatedData = data || {};
  const queryParams = getQueryParams();
  const optionalAnalyticsQueryParams = analyticsQueryParams || [];
  optionalAnalyticsQueryParams.forEach(optionalField => {
    const optionalFieldQueryParam = queryParams[optionalField];
    if (optionalFieldQueryParam) {
      updatedData[optionalField] = optionalFieldQueryParam;
    }
  });

  return ajax.post('open/content/v2/leadform', updatedData, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
}

const FULLNAME_FIELD = 'C_FullName';
const ZIPCODE_FIELD = 'C_Zip_Postal';

const isEloquaFormDataHack = (fieldState = []) =>
  fieldState.some(field => field.name === FULLNAME_FIELD);

// We're testing a lead form with only Full Name and a few other fields.
// This is a hack to fix an issue where Eloqua tracking
// requires the data for unused fields.
function getEloquaFormData(fieldState = []) {
  const fullName = fieldState.find(field => field.name === FULLNAME_FIELD) || {};

  if (fullName.value) {
    /* eslint-disable camelcase */
    const [C_FirstName, ...lastName] = fullName.value.split(' ');
    const C_LastName = lastName.length > 0 ? lastName.join(' ') : 'N/A';
    return {
      C_FirstName,
      C_LastName
    };
    /* eslint-enable camelcase */
  } else {
    return {};
  }
}

export const fieldMap = {
  annualRevenue: 'C_Company_Revenue_Ranges1',
  businessName: 'C_Company',
  businessEmail: 'C_EmailAddress',
  businessPhoneNumber: 'C_BusPhone',
  businessZipCode: ZIPCODE_FIELD,
  fullName: FULLNAME_FIELD,
  abTest: 'ab_test'
};

/* eslint-disable no-unused-vars */
const postLeadGenForCorpPages = (rawData, analyticsQueryParams, formId) => {
  const updatedFieldMap = { ...fieldMap, ...getBotTrapFieldMap() };
  const fieldState = Object.keys(updatedFieldMap).reduce((resultList, key) => {
    resultList.push({
      name: updatedFieldMap[key],
      value: rawData[key]
    });
    return resultList;
  }, []);

  /* ### original pre-submit logic starts ### */
  const isEloquaHack = isEloquaFormDataHack(fieldState);

  const eloquaCookie = getEloquaCookie();
  const eloquaFormData = isEloquaHack ? getEloquaFormData(fieldState) : {};
  const protocol = get(window, 'location.protocol') || '';
  const host = get(window, 'location.host') || '';
  // versions of IE before 11 may drop leading slash in pathname
  const pathname = get(window, 'location.pathname') || '';
  const query = get(window, 'location.search') || '';
  const currentUrl = `${protocol}//${host}${pathname}${query}`;
  /*eslint-disable camelcase */
  const formData = {
    elqCustomerGUID: eloquaCookie,
    pageID: 'CORPORATE',
    ab_test: 'control',
    referringURL: currentUrl,
    eloquaUUID: formId,
    ...eloquaFormData
  };
  /*eslint-enable camelcase */

  fieldState.forEach(field => {
    if (field.name === ZIPCODE_FIELD) {
      formData[field.name] = field.value ? field.value : '99999';
    } else {
      formData[field.name] = field.value;
    }
  });

  if (isEloquaHack) {
    delete formData['C_FullName'];
  }
  /* ### original pre-submit logic ends ### */
  return postLeadGen(formData, analyticsQueryParams);
};

export { postLeadGenForCorpPages };
