'use strict';
import get from 'lodash.get';
import { linkSplit } from 'src/universal/utils/url/index';
import { mapUrlWithDtmLinkQuery } from 'service_modules/models/dtm-tagging';
import { getPZNEncrypted } from 'service_modules/services/open-shop-platform/get-offer-gating';
import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import {
  getWindowObject,
  getDocumentObject
} from 'src/universal/utils/window-document/index';
import { isServer } from '../../utils/is';
import { appendTrackingParams } from '../../../core_modules/utils/url.utils';
import has from 'lodash.has';
import { store } from 'src/services/store/store';
import { isProspect } from 'src/universal/redux/userType';
import logger from 'src/universal/utils/IsomorphicLogger';
import { triggerCmPznClickEvent } from 'src/universal/utils/orchestraCmPznClickEvent';
import { getCmUpgradeEep } from 'src/universal/utils/orchestraUtils';
import { getDispatchFunc } from 'src/universal/utils/reduxStoreUtil';
import isOfferFromPzn from 'src/universal/utils/isOfferFromPzn';
import {
  getSelecteddDesignInfo,
  addCardDesignFieldsToForm,
  addCardDesignFieldsToUrl
} from 'src/universal/utils/cardColorChoices';
import { getShouldUseDynamicAppFlow } from 'src/universal/components/Experiments/G147/redux';
import { getPageFeatureFlags } from 'src/universal/redux/page';

const _ = { has };

const documentObj = getDocumentObject();
const windowObj = getWindowObject();

export const isInternationalPage = url => {
  return !url.includes('us/');
};

export function updateFeatures(existingFeatures, cardObj) {
  if (
    existingFeatures &&
    Array.isArray(existingFeatures.descriptionList) &&
    cardObj &&
    cardObj.pznRate
  ) {
    existingFeatures.descriptionList.push(cardObj.pznRate);
  }
  return existingFeatures;
}

export function extractEepValFromCardObj(cardObj) {
  const offerObj = cardObj.pznOffer || cardObj.offer;
  return offerObj && offerObj.eep ? offerObj.eep : '';
}

export function getOfferButtonMeta(offerButtonMetaObj, cardObj) {
  if (offerButtonMetaObj) {
    if (cardObj.offerButton) {
      offerButtonMetaObj.text = cardObj.offerButton;
    }

    const eepVal = extractEepValFromCardObj(cardObj);
    const eepWithTrailingSlash = eepVal ? eepVal + '/' : eepVal;

    if (eepVal && offerButtonMetaObj.url) {
      if (!offerButtonMetaObj.urlBeforeEEP) {
        offerButtonMetaObj.urlBeforeEEP = offerButtonMetaObj.url;
      }
      const split = linkSplit(offerButtonMetaObj.urlBeforeEEP);
      split.appendTo(eepWithTrailingSlash);
      offerButtonMetaObj.url = split.getPathOnlyUrl();
    }
  }
  return offerButtonMetaObj;
}

export function getHeaderUrls(card, response) {
  let learnMoreUrl = card.learnMore.url;
  let dtmLearnMore = Object.assign({}, card.learnMore.dtmTag);
  let dtmCardArt = Object.assign({}, dtmLearnMore);
  let dtmCardTitle = Object.assign({}, dtmLearnMore);

  dtmCardArt.productName = dtmLearnMore.productName;
  dtmCardTitle.productName = dtmLearnMore.productName;

  if (response.pageData.cardArt && response.pageData.cardArt.dtmTag) {
    dtmCardArt = Object.assign({}, dtmCardArt, response.pageData.cardArt.dtmTag);
  } else {
    dtmCardArt = Object.assign({}, dtmCardArt, { tag: 'CardArt', type: 'linknav' });
  }

  if (response.pageData.cardHeadline && response.pageData.cardHeadline.dtmTag) {
    dtmCardTitle = Object.assign(
      {},
      dtmCardTitle,
      response.pageData.cardHeadline.dtmTag
    );
  } else {
    dtmCardTitle = Object.assign({}, dtmCardTitle, {
      tag: 'CardTitle',
      type: 'linknav'
    });
  }

  if (learnMoreUrl.indexOf('?') > -1) {
    learnMoreUrl = learnMoreUrl.split('?')[0];
  }

  const splitLearnMore = linkSplit(learnMoreUrl);
  const learnMoreEep = extractEepValFromCardObj(card);
  const slashedLearnMoreEep = learnMoreEep ? learnMoreEep + '/' : learnMoreEep;
  let urlVal = '';
  if (slashedLearnMoreEep) {
    splitLearnMore.appendTo(slashedLearnMoreEep);
    urlVal = splitLearnMore.getPathOnlyUrl();
  }

  if (!isServer && isInternationalPage(window.location.pathname)) {
    urlVal = appendTrackingParams(urlVal, window.location.search);
  }

  return {
    cardArtUrl: urlVal,
    cardArtDtm: dtmCardArt,
    cardTitleUrl: mapUrlWithDtmLinkQuery(urlVal, dtmCardTitle)
  };
}

export function processTncUrl(tncObj, eepVal) {
  const split = linkSplit(tncObj.url);

  split.appendTo(eepVal + (tncObj.urlAppend ? tncObj.urlAppend : ''));

  let resultUrl = decodeURIComponent(split.toString());

  return resultUrl;
}

export function ensureBenefitOfferTerms(itemList, keyIndexHash) {
  const offerTermIndex = keyIndexHash['offerTerms'];
  const benefitTermIndex = keyIndexHash['benefitTerms'];

  if (typeof offerTermIndex === 'number' && typeof benefitTermIndex === 'number') {
    itemList.splice(benefitTermIndex, 1);
  }
  return itemList;
}

const isUserProspect = () => {
  let isProspectUser = true;
  if (typeof store !== 'undefined') {
    isProspectUser = isProspect(store.getState() || {});
  }
  return isProspectUser;
};

const isUsingDynamicAppFlow = () => {
  let useDynamicAppFlow = false;
  if (typeof store !== 'undefined') {
    useDynamicAppFlow = getShouldUseDynamicAppFlow(store.getState() || {})
      ?.dynamicAppFlow;
  }
  return useDynamicAppFlow;
};

const usingEapplyTestEnv = () => {
  let useEapplyTestEnv = false;
  if (typeof store !== 'undefined') {
    useEapplyTestEnv = getPageFeatureFlags(store.getState() || {})?.useEapplyTestEnv;
  }
  return useEapplyTestEnv;
};

export function getTermLinks(termLinks, card, response) {
  let eepVal = extractEepValFromCardObj(card);
  const hasGSuite = Boolean(card.pznOffer && card.pznOffer.googleCloudOffer);
  const requiredTermLinks = [];
  const keyIndexHash = {};

  const linksHashToUse = {};
  const linksHashUpgrade = {};

  Object.keys(termLinks).forEach(key => {
    if (['offerTermsUpgrade', 'ratesFeesUpgrade'].indexOf(key) > -1) {
      linksHashUpgrade[key] = termLinks[key];
    } else {
      linksHashToUse[key] = termLinks[key];
    }
  });

  if (!isUserProspect() && get(card, 'pznOffer.isUpgradeCard')) {
    const upgradeEepVal = getCmUpgradeEep(card.shortName);
    if (upgradeEepVal) {
      eepVal = upgradeEepVal;
      Object.keys(linksHashUpgrade).forEach(key => {
        const upgradeItem = linksHashUpgrade[key];
        const targetItem = linksHashToUse[key.replace('Upgrade', '')];
        if (targetItem) {
          targetItem.url = upgradeItem.url;
          targetItem.dtmTag = upgradeItem.dtmTag;
          targetItem.urlAppend = upgradeItem.urlAppend;
        }
      });
    }
  }

  Object.keys(linksHashToUse).forEach(function(key, index) {
    const tncObj = linksHashToUse[key];

    const allowedToShow =
      tncObj &&
      (!tncObj.showForGSuiteOnly ||
        (tncObj.showForGSuiteOnly === true && hasGSuite === true));

    if (allowedToShow) {
      const resultUrl = processTncUrl(tncObj, eepVal);
      keyIndexHash[key] = index;
      const keyRevised = key === 'ratesFees' ? 'rates&Fees' : key;
      requiredTermLinks.push({
        data: {
          html: response?.pageData?.[`${keyRevised}Label`] ?? '',
          href: resultUrl
        },
        dtmTag: tncObj.dtmTag
      });
    }
  });

  return ensureBenefitOfferTerms(requiredTermLinks, keyIndexHash);
}

function getPZNForm(url, data, cardDesignInfo, useDynamicApp) {
  return new Promise(resolve => {
    if (typeof documentObj.getElementById === 'function') {
      const pznForm = documentObj.getElementById('pznApplicationForm');
      const pznOfferGatingResp = documentObj.getElementById('pznOfferGatingResp');
      const gambitIndicator = documentObj.getElementById('gambitIndicator');

      gambitIndicator.value = useDynamicApp;

      if (cardDesignInfo.allowDesignVariation) {
        addCardDesignFieldsToForm(pznForm, cardDesignInfo);
      }

      if (data && url && pznForm && pznOfferGatingResp) {
        pznForm.action = url;
        pznOfferGatingResp.value = data;
      }
    }
    return resolve();
  });
}

export function getPreQualSessionToken(toaHash, params = {}) {
  const url = toaHash
    ? `open/affiliate-targeted-offer-session-token/?toaHash=${toaHash}`
    : 'open/affiliate-targeted-offer-session-token';

  return ajax.get(url, {
    'set-cookie': params['set-cookie']
  });
}

function redirectPost(url, data, cardDesignInfo) {
  const form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'post';
  form.action = url;
  const paramKeys = Object.keys(data);
  paramKeys.forEach(key => {
    let formVal = document.createElement('input');
    formVal.type = 'hidden';
    formVal.name = key;
    formVal.value = data[key];
    form.appendChild(formVal);
  });
  if (cardDesignInfo.allowDesignVariation) {
    addCardDesignFieldsToForm(form, cardDesignInfo);
  }
  form.submit();
}

// eslint-disable-next-line complexity
export function getApplyButtonObj(applyObj, card, pageId, eepAppended = false) {
  const formattedUrl = mapUrlWithDtmLinkQuery(applyObj.url, applyObj.dtmTag);
  const split = linkSplit(formattedUrl);
  const offerObj = card.pznOffer || card.offer;
  const eepVal = extractEepValFromCardObj(card);

  const eepAppendVal = eepVal
    ? eepVal + (applyObj.urlAppend ? applyObj.urlAppend : '')
    : '';

  if (eepAppendVal) {
    split.appendTo(eepAppendVal);
  }

  const applyBtn = Object.assign({}, applyObj);
  let applyUrl = eepAppended ? applyObj.url : split.toString();
  let usePZN;
  let pznEEP;
  // EVAN: THIS NEEDS TO BE REMOVED BEFORE WE PUSH TO PROD
  if (usingEapplyTestEnv()) {
    applyUrl = applyUrl.replace(
      'https://qwww.americanexpress.com',
      'https://dxpcardappav4-qa.americanexpress.com'
    );
  }

  if (offerObj) {
    usePZN = isOfferFromPzn(offerObj);
    pznEEP = offerObj.eep;
  }

  let cardDesignInfo = { allowDesignVariation: false };

  if (usePZN) {
    applyBtn.onClick = () => {
      const useDynamicApp = isUsingDynamicAppFlow();
      cardDesignInfo = getSelecteddDesignInfo(card.shortName);
      const dispatch = getDispatchFunc();
      dispatch({ type: 'NEED_MODAL_SPINNER', data: true });

      if (typeof documentObj.getElementById !== 'function') {
        return;
      }
      const pmcCode = card.pmcCode;
      const iaCode = offerObj.iaCode;
      const cardShortName = card.shortName;
      const pznForm = document.getElementById('pznApplicationForm');
      const pznOfferGatingResp = document.getElementById('pznOfferGatingResp');

      if (pznForm) {
        pznForm.action = '';
      }
      if (pznOfferGatingResp) {
        pznOfferGatingResp.value = '';
      }

      const handleOgErr = (response = {}, reason) => {
        logger(`OpenOgFailed-ClientSide-${reason}`, {
          httpStatus: response.status,
          hasEncryptedData: false
        });
        dispatch({ type: 'NEED_MODAL_SPINNER', data: false });
      };

      if (pznForm) {
        getPZNEncrypted({ pmcCode, pageId, cardShortName, EEP: pznEEP })
          .then(response => {
            const encryptedData = get(response, 'data.data');
            if (response.status !== 200 || !encryptedData) {
              handleOgErr(response, 'NoData');
              return null;
            }
            getPZNForm(applyUrl, encryptedData, cardDesignInfo, useDynamicApp).then(
              () => {
                pznForm.submit();
                const needCmPznClickEvent = !isUserProspect();
                if (needCmPznClickEvent) {
                  triggerCmPznClickEvent({ pageId, offerEep: pznEEP, iaCode });
                }

                setTimeout(() => {
                  dispatch({ type: 'NEED_MODAL_SPINNER', data: false });
                }, 2000);
              }
            );
          })
          .catch(errResponse => {
            handleOgErr(errResponse, 'HttpErr');
          });
      } else {
        dispatch({ type: 'NEED_MODAL_SPINNER', data: false });
      }
    };
  }

  const resultObj = {
    ...(applyObj || {}),
    url: applyUrl
  };

  // if card has a secure offer, attach it to the apply button
  if (applyBtn.onClick) {
    resultObj.onClick = event => {
      const evt = event || window.event;
      if (evt && evt.preventDefault) {
        evt.preventDefault();
      }
      applyBtn.onClick();
    };
  } else {
    resultObj.onClick = event => {
      const useDynamicApp = isUsingDynamicAppFlow();
      cardDesignInfo = getSelecteddDesignInfo(card.shortName);
      if (useDynamicApp) {
        const formFields = {
          gambitIndicator: useDynamicApp
        };
        redirectPost(applyUrl, formFields, cardDesignInfo);
        event.preventDefault();
      } else if (windowObj.location) {
        let urlVal = resultObj.url;
        if (cardDesignInfo.allowDesignVariation) {
          urlVal = addCardDesignFieldsToUrl(urlVal, cardDesignInfo);
        }
        event.preventDefault();
        windowObj.location.href = urlVal;
      }
    };
  }
  return resultObj;
}

export function getLearnMoreLink(learnMoreObj, card, response) {
  const split = linkSplit(learnMoreObj.url);
  const eepVal = extractEepValFromCardObj(card);
  const urlAppend = learnMoreObj?.urlAppend || '';
  split.appendTo(
    `${eepVal}${
      urlAppend.startsWith('?') || urlAppend.length <= 0 ? '/' : ''
    }${urlAppend}`
  );

  return {
    title: response?.pageData?.learnMoreLabel ?? '',
    type: learnMoreObj.type,
    url: split.getPathOnlyUrl(),
    dtmTag: learnMoreObj.dtmTag
  };
}

export function getUpgradeLink(upgradeCtaObj, card) {
  const formattedUrl = mapUrlWithDtmLinkQuery(
    upgradeCtaObj.url,
    upgradeCtaObj.dtmTag
  );
  const split = linkSplit(formattedUrl);
  const eepVal = getCmUpgradeEep(card.shortName);
  let allowUpgradeTo = false;
  if (eepVal) {
    allowUpgradeTo = true;
    split.appendTo(
      eepVal + (upgradeCtaObj.urlAppend ? upgradeCtaObj.urlAppend : '')
    );
  }

  return {
    ...(upgradeCtaObj || {}),
    url: split.toString(),
    allowUpgradeTo
  };
}

export function getCompareTrayObj(compareTrayObj, pageData, global) {
  const split = linkSplit(compareTrayObj.url);
  const eepVal = pageData.initialEEP || global.defaultEEP;
  const urlAppend = compareTrayObj.urlAppend || '';
  split.appendTo(
    `${eepVal}${
      urlAppend.startsWith('?') || urlAppend.length <= 0 ? '/' : ''
    }${urlAppend}`
  );
  const compareTrayCopy = Object.assign({}, compareTrayObj);
  compareTrayCopy.url = split.toString();
  return compareTrayCopy;
}

export function processDtmForVacAndCompareData(data) {
  data.pageData.cards.forEach(function(card) {
    const { sections, apply, learnMore, offer } = card;

    let baseApplyUrl;
    let baseLearnMoreUrl;

    if (!isServer && isInternationalPage(window.location.pathname)) {
      ['readyToApply', 'needMoreInfo'].forEach(sectionKey => {
        if (_.has(card, `sections.${sectionKey}.contentGroup`)) {
          sections[sectionKey].contentGroup.forEach(contentItem => {
            contentItem.url = appendTrackingParams(
              contentItem.url,
              window.location.search
            );
          });
        }
      });

      if (apply.url) {
        baseApplyUrl = appendTrackingParams(apply.url, window.location.search);
      }

      if (learnMore.url) {
        baseLearnMoreUrl = appendTrackingParams(
          learnMore.url,
          window.location.search
        );
      }
    } else {
      baseApplyUrl = apply.url;
      baseLearnMoreUrl = learnMore.url;
    }

    if (baseApplyUrl) {
      apply.url = mapUrlWithDtmLinkQuery(baseApplyUrl, apply.dtmTag);
    }

    if (baseLearnMoreUrl) {
      learnMore.url = mapUrlWithDtmLinkQuery(baseLearnMoreUrl, learnMore.dtmTag);
    }

    if (offer && offer.url && offer.dtmTag) {
      offer.url = mapUrlWithDtmLinkQuery(offer.url, offer.dtmTag);
    }
  });

  if (data.pageData.benefits && data.pageData.benefits.content) {
    data.pageData.benefits.content.map(benefit => {
      if (!benefit.learnMore) {
        return null;
      }
      const { url, dtmTag } = benefit.learnMore;
      benefit.learnMore.url = mapUrlWithDtmLinkQuery(url, dtmTag);
      return benefit;
    });
  }

  if (data.pageData.compare) {
    data.pageData.compare.url = mapUrlWithDtmLinkQuery(
      data.pageData.compare.url,
      data.pageData.compare.dtmTag
    );
  }

  return data;
}

export function orderCardsByOrderProp(cards) {
  let isProspectUser = isUserProspect();

  function hasNoOrder(item) {
    return typeof item.order === 'undefined' || !Number.isInteger(item.order);
  }

  if (cards && Array.isArray(cards) && cards.length > 0) {
    if (isProspectUser) {
      // NOTE: Sometimes the PZN response contains only several cards not all. These cards would have the order value.
      //   Then the BE API sends to FE the array with all cards, but those not returned by PZN would not have the order value.
      //   A business requirement in case when even one card comes without order is to render cards in a default order,
      //     that is in the order they are in the cards array.
      if (cards.some(hasNoOrder)) {
        cards.forEach((item, index) => {
          item.order = index;
        });
      }
    } else {
      /*
      Orchestra sorting logic (per product team):
      (1) we show whatever CM PZN returns first then default thereafter
      (2) source: https://aexp-mobile-and-web.slack.com/archives/G4XRGE072/p1632939594449000
      */
      let highestExistingOrder = 0;
      const noOrderList = [];
      cards.forEach(card => {
        if (hasNoOrder(card)) {
          noOrderList.push(card);
        } else {
          if (highestExistingOrder < card.order) {
            highestExistingOrder = card.order;
          }
        }
      });

      if (noOrderList.length > 0) {
        let nextNum = highestExistingOrder + 1;
        noOrderList.forEach(card => {
          card.order = nextNum;
          nextNum = nextNum + 1;
        });
      }
    }

    // we need to sort cards by the pzn data
    cards.sort((itemA, itemB) => {
      let orderA = parseInt(itemA.order, 10);
      let orderB = parseInt(itemB.order, 10);
      orderA = isNaN(orderA) ? 0 : orderA;
      orderB = isNaN(orderB) ? 0 : orderB;

      if (orderA > orderB) {
        return 1;
      }
      if (orderA < orderB) {
        return -1;
      }
      return 0;
    });
  }
}

export function getApplyUrl(pageComponents) {
  if (pageComponents.interstitialToApp) {
    const offerObj = pageComponents.offer || {};
    let eepVal = offerObj?.eep || null;

    if (!eepVal && offerObj.initialEEP) {
      eepVal = offerObj.initialEEP;
    }

    if (
      !eepVal ||
      pageComponents.interstitialToApp.button.url.indexOf(eepVal) > -1
    ) {
      return `${pageComponents.interstitialToApp.button.url}`;
    }

    return `${pageComponents.interstitialToApp.button.url}${eepVal}`;
  }

  const split = linkSplit(pageComponents.apply.url);
  const offerObj = pageComponents.offer || {};
  let eepVal = offerObj && offerObj.eep ? offerObj.eep : null;

  if (!eepVal && offerObj.initialEEP) {
    eepVal = offerObj.initialEEP;
  }

  const eepAppendVal = eepVal
    ? eepVal + (pageComponents.apply.urlAppend ? pageComponents.apply.urlAppend : '')
    : '';

  if (eepAppendVal) {
    split.appendTo(eepAppendVal);
  }

  return split.toString();
}

export function updateInterstitial(pageComponents) {
  if (!pageComponents.interstitialToApp) {
    return null;
  }

  return {
    header: pageComponents.interstitialToApp.header,
    description: pageComponents.interstitialToApp.description,
    button: {
      type: pageComponents.interstitialToApp.button.type,
      title: pageComponents.interstitialToApp.button.title,
      url: getApplyUrl(pageComponents),
      dtmTag: {
        eventAction: pageComponents.interstitialToApp.button.dtmTag.eventAction,
        eventAsset: pageComponents.interstitialToApp.button.dtmTag.eventAsset
      }
    },
    dtmTag: {
      eventAction: pageComponents.interstitialToApp.dtmTag.eventAction,
      eventAsset: pageComponents.interstitialToApp.dtmTag.eventAsset
    },
    cancelButton: {
      type: pageComponents.interstitialToApp.cancelButton.type,
      title: pageComponents.interstitialToApp.cancelButton.title,
      dtmTag: {
        eventAction:
          pageComponents.interstitialToApp.cancelButton.dtmTag.eventAction,
        eventAsset: pageComponents.interstitialToApp.cancelButton.dtmTag.eventAsset
      }
    }
  };
}
