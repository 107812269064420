import { findByKeyValue } from 'core_modules/utils/array.utils.js';

/*
  this will be shared by multiple models/pages
*/

export function getPageSpecificPhoneObj(globalData) {
  globalData = globalData || {};
  const pageType = globalData.currentPageType;
  const menuLinkObj =
    globalData.globalHeaderPage && globalData.globalHeaderPage.rightMenus
      ? findByKeyValue(globalData.globalHeaderPage.rightMenus, 'type', 'link', {})
      : null;

  // checks to see if we are using graphql data or legacy
  const menuLinkObjText = menuLinkObj.menuText
    ? menuLinkObj.menuText
    : menuLinkObj.text;

  if (!pageType || !menuLinkObj || !menuLinkObjText) {
    return null;
  }

  const pageSpecificObj = menuLinkObjText[pageType];

  if (pageSpecificObj && pageSpecificObj.title && pageSpecificObj.url) {
    return pageSpecificObj;
  }

  return null;
}
