'use strict';

import fetch from 'fetch-retry';
import { getApiHost } from 'acq-system-api';
import {
  setCurrentPageType,
  setCurrentPageTypeGraphQL
} from './response-preprocessors';
import { DEFAULT_CONFIG } from 'src/fetch-default-config';
import { parseApiResponse } from 'src/fetch-response';
import { getProxyOptions, getCorrelationId } from 'src/fetch-default-config';
import { logRequestData } from 'src/logger';
import GraphQLDataParser from './graphql/data-parser';
import {
  isServer,
  isClient,
  setHeaders,
  validateTimeout,
  appendCorrelationIdToUrl
} from './utils';
import $ from 'jquery';
import { InitAjaxRetry } from './InitAjaxRetry';
import isomorphicLogger from 'src/universal/utils/IsomorphicLogger';
import { logError } from 'src/universal/utils/IsomorphicLogger/logError';
import { getIsomorphicCookies } from 'src/universal/utils/getIsomorphicCookies';

const logSsrHeaders = (headers, url) => {
  const headersStr = JSON.stringify(headers) || '';
  if (headersStr.length > 6000) {
    console.log(
      `[GCP_HEADERS] url:${url}; size:${headersStr.length}; value:${headersStr}`
    );
  }
};

export default {
  get: (url, config = {}) => {
    config.requestName = config.requestName || 'app-request-get';
    const method = 'GET';
    const refferer = isClient ? window.location.pathname : global.reqUrl;
    const endpointUrl = `${getApiHost(
      isClient ? window.location.hostname : global.reqHost
    )}/us/cardshop-api/api/v1/${url}`;
    const settings = { ...(getProxyOptions() || {}), ...(config.settings || {}) };
    const destination = appendCorrelationIdToUrl(endpointUrl, getCorrelationId());
    console.log(
      `[${isClient ? 'CSR' : 'SSR'}_CARDSHOP_FE] API Host: ${
        isClient ? window.location.hostname : global.reqHost
      }`
    );
    console.log(`[CARDSHOP_BE] Destination: ${destination}`);
    const options = Object.assign({}, DEFAULT_CONFIG, settings, {
      method,
      credentials: 'include',
      cache: 'no-cache'
    });

    options.headers = setHeaders({
      headers: options.headers,
      url: destination,
      cookie: config['set-cookie'] || getIsomorphicCookies()
    });
    options.timeout = validateTimeout({ timeout: options.timeout, method });

    const laas = logRequestData({
      method,
      fetchOptions: options
    });

    const start = new Date().getTime();
    const msg = `${isServer ? 'SSR' : 'CSR'} API_ACCESS] ${
      isServer ? `SRC:${global.reqHost}` : null
    } ${method} url:${destination} UUID=${global.uuid}`;

    const clientRequestStart = Date.now();

    if (isClient) {
      return new Promise((resolve, reject) => {
        const handleSuccess = ({
          json,
          statusText,
          xhr: { status },
          retriesAttempted = 0,
          lastFailedRequestTimestamp
        }) => {
          if (!json) {
            return reject(statusText);
          }

          const responseTime = Date.now() - clientRequestStart;
          isomorphicLogger(`${config.requestName}-success`, {
            url: refferer,
            reqUrl: endpointUrl,
            responseTime,
            retriesAttempted,
            lastFailedRequestTimestamp
          });
          return resolve({
            url: destination,
            data: setCurrentPageType(json, destination),
            status
          });
        };

        const ajaxRetry = new InitAjaxRetry({
          method,
          url: destination,
          xhrFields: {
            withCredentials: true
          },
          requestName: config.requestName,
          reject,
          clientRequestStart,
          handleSuccess
        });

        ajaxRetry.sendRequest();
      });
    }

    logSsrHeaders(options.headers, global.reqUrl);

    return fetch(destination, options)
      .then(function(response = {}) {
        console.info(
          `[Response ${msg} ${response.status} ${new Date().getTime() - start} ms`
        );
        if (!response.ok) {
          return Promise.reject({
            ok: response.ok,
            status: response.status,
            statusText: response.statusText
          });
        }
        return response.json().then(parseApiResponse(response));
      })
      .then(function(response = {}) {
        laas(response);
        return {
          url: response.url,
          headers: response.headers,
          data: setCurrentPageType(response.data, '/' + url),
          status: 200
        };
      })
      .catch(function(error) {
        laas({ error });
        console.info(
          `[Error ${msg} ${error.errno} ${error.code} ${new Date().getTime() -
            start} ms`
        );
        const errorText = `FATAL: fetch ${method} failed`;

        // this line just be here in order for the error to be propergated down to the caller
        error.reqUrl = destination;
        error.errorCode = error.status;

        logError(config.requestName, error, { errorText });

        return Promise.reject(error);
      });
  },
  post: (url, data = {}, config = {}, isExternal = false) => {
    config.requestName = config.requestName || 'app-request-post';
    const method = 'POST';
    const BASE_URL = `${getApiHost(
      isClient ? window.location.hostname : global.reqHost
    )}/us/cardshop-api/api/v1`;
    const settings = { ...(getProxyOptions() || {}), ...(config.settings || {}) };
    const destination = isExternal
      ? appendCorrelationIdToUrl(url, getCorrelationId())
      : appendCorrelationIdToUrl(BASE_URL + '/' + url, getCorrelationId());
    const options = Object.assign({}, DEFAULT_CONFIG, settings, {
      method,
      body: data
    });

    options.headers = setHeaders({
      headers: options.headers,
      url: destination,
      cookie: config['set-cookie'] || getIsomorphicCookies(),
      contentType: config.contentType
    });
    options.timeout = validateTimeout({ timeout: options.timeout, method });

    const laas = logRequestData({
      method,
      fetchOptions: options
    });

    const start = new Date().getTime();
    const msg = `${isServer ? 'SSR' : 'CSR'} API_ACCESS] ${
      isServer ? `SRC:${global.reqHost}` : null
    } ${method} url:${destination} UUID=${global.uuid} `;

    if (isClient) {
      return new Promise((resolve, reject) => {
        $.ajax({
          method,
          url: destination,
          data,
          xhrFields: {
            withCredentials: true
          }
        })
          .done(function(json, statusText, xhr) {
            if (!json) {
              return reject(statusText);
            }

            return resolve({
              url: destination,
              data,
              status: xhr.status
            });
          })
          .fail(reject);
      });
    }

    logSsrHeaders(options.headers, global.reqUrl);

    return fetch(destination, options)
      .then(function(response = {}) {
        console.info(
          `[Response ${msg} ${response.status} ${new Date().getTime() - start} ms`
        );
        if (response.status === 500 || !response.ok) {
          return Promise.reject(response);
        }
        return response.json().then(parseApiResponse(response));
      })
      .then(function(response = {}) {
        laas(response);
        return {
          url: response.url,
          headers: response.headers,
          data: response.data,
          status: 200
        };
      })
      .catch(function(error) {
        laas({ error });
        console.info(
          `[Error ${msg} ${error.errno} ${error.code} ${new Date().getTime() -
            start} ms`
        );
        console.error(
          `FATAL: fetch ${method} failed `,
          JSON.stringify(error).replace(/(?:\\[rn]|[\r\n]+)+/g, '')
        );
      });
  },
  graphQLPost: (query, config = {}) => {
    config.requestName = config.requestName || 'app-request-graphql';
    const method = 'POST';
    const data = JSON.stringify(query);
    const refferer = isClient ? window.location.pathname : global.reqUrl;

    const endpointUrl = `${getApiHost(
      isClient ? window.location.hostname : global.reqHost
    )}/oneshop/cardshopql`;

    const destination = appendCorrelationIdToUrl(endpointUrl, getCorrelationId());
    console.log(
      `[${isClient ? 'CSR' : 'SSR'}_CARDSHOP_GRAPHQL_FE] API Host: ${
        isClient ? window.location.hostname : global.reqHost
      } UUID=${global.uuid}`
    );
    console.log(
      `[CARDSHOP_GRAPHQL_BE] Destination: ${destination} UUID=${global.uuid}`
    );
    const settings = { ...(getProxyOptions() || {}), ...(config.settings || {}) };
    const options = Object.assign({}, DEFAULT_CONFIG, settings, {
      method,
      credentials: 'include',
      cache: 'no-cache',
      body: data
    });

    options.headers = setHeaders({
      headers: options.headers,
      url: destination,
      cookie: config['set-cookie'] || getIsomorphicCookies(),
      contentType: 'application/json'
    });
    options.timeout = validateTimeout({ timeout: options.timeout, method });

    const laas = logRequestData({
      method,
      fetchOptions: options
    });

    const start = new Date().getTime();
    const msg = `${isServer ? 'SSR' : 'CSR'} API_ACCESS] ${
      isServer ? `SRC:${global.reqHost}` : null
    } ${method} url:${destination} UUID=${global.uuid}`;

    if (isClient) {
      const clientRequestStart = Date.now();
      return new Promise((resolve, reject) => {
        const handleSuccess = ({
          json,
          statusText,
          xhr: { status },
          retriesAttempted = 0,
          lastFailedRequestTimestamp
        }) => {
          if (!json) {
            return reject(statusText);
          }
          let mappedData = json.data;
          const responseTime = Date.now() - clientRequestStart;
          isomorphicLogger(`${config.requestName}-success`, {
            url: refferer,
            reqUrl: endpointUrl,
            responseTime,
            retriesAttempted,
            lastFailedRequestTimestamp
          });

          if (!config.skipParser) {
            mappedData = GraphQLDataParser(mappedData);
          }

          if (config.extractData) {
            mappedData = mappedData.cardShop;
          }

          return resolve({
            url: destination,
            data: setCurrentPageTypeGraphQL(mappedData, config.queryName),
            status
          });
        };

        const ajaxRetry = new InitAjaxRetry({
          method,
          url: destination,
          contentType: 'application/json',
          data,
          xhrFields: {
            withCredentials: true
          },
          requestName: config.requestName,
          reject,
          clientRequestStart,
          handleSuccess
        });

        ajaxRetry.sendRequest();
      });
    }

    const optionsCopy = JSON.parse(JSON.stringify(options));
    delete optionsCopy.body;
    console.log(
      `[SSR_CARDSHOP] Options (partial): ${JSON.stringify(optionsCopy)} UUID=${
        global.uuid
      }`
    );

    logSsrHeaders(options.headers, refferer);
    const serverRequestStart = Date.now();
    return fetch(destination, options)
      .then(function(response = {}) {
        if (!response.ok) {
          return Promise.reject({
            ok: response.ok,
            status: response.status,
            statusText: response.statusText
          });
        }
        const responseTime = Date.now() - serverRequestStart;
        isomorphicLogger(`${config.requestName}-success`, {
          url: refferer,
          reqUrl: endpointUrl,
          responseTime
        });
        return response.json().then(parseApiResponse(response));
      })
      .then(function(response = {}) {
        laas(response);

        let mappedData = response.data.data;

        if (!config.skipParser) {
          mappedData = GraphQLDataParser(mappedData);
        }

        if (config.extractData) {
          mappedData = mappedData.cardShop;
        }

        const value = {
          url: response.url,
          headers: response.headers,
          data: setCurrentPageTypeGraphQL(mappedData, config.queryName),
          status: 200
        };

        return value;
      })
      .catch(function(error) {
        laas({ error });
        console.info(
          `[Error ${msg} ${error.errno} ${error.code} ${new Date().getTime() -
            start} ms UUID=${global.uuid}`
        );
        console.error(
          `FATAL: fetch ${method} failed `,
          JSON.stringify(error).replace(/(?:\\[rn]|[\r\n]+)+/g, '')
        );
        console.log(`[SSR_CARDSHOP] Options (complete): ${JSON.stringify(options)}`);

        // this line just be here in order for the error to be propergated down to the caller
        error.reqUrl = destination;
        error.errorCode = error.status;
        return Promise.reject(error);
      });
  }
};
