import $ from 'jquery';
import { FETCH_RETRY_DELAY, FETCH_MAX_RETRIES } from 'src/fetch-default-config';
import { logError } from 'src/universal/utils/IsomorphicLogger/logError';

export class InitAjaxRetry {
  constructor(options) {
    this.retryAttemptedCount = 0;
    this.options = options;
    this.requestName = this.options.requestName || 'app-request';
    this.clientRequestStartTime = this.options.clientRequestStart || Date.now();
    this._handleSuccess = this.options.handleSuccess || (() => ({}));
    this.requestTimeStamps = {};
  }

  _getTimeStamp = () => new Date().toISOString().replace(/(.*)T(.*)Z$/g, '$1 $2');
  _calcTimeToError = () => Date.now() - this.clientRequestStartTime;

  _handleFailed = ({ errorCode, responseText, statusText }) => {
    const {
      options: { url: reqUrl, requestName, reject: rejectPromise },
      retryAttemptedCount,
      requestTimeStamps
    } = this;
    const isLastRetry = retryAttemptedCount === FETCH_MAX_RETRIES;

    if (isLastRetry) {
      const errorObj = {
        errorCode,
        responseText,
        reqUrl,
        statusText,
        lastFailedRequestTimestamp: requestTimeStamps[retryAttemptedCount],
        retriesAttempted: retryAttemptedCount,
        text: `Ajax - Last Retry Attempt Failed`,
        timeToError: this._calcTimeToError()
      };
      logError(`${requestName}-retry-failed-last`, errorObj);
      if (rejectPromise) {
        rejectPromise(errorObj);
      }
      return;
    }

    setTimeout(() => {
      this.retryAttemptedCount++;
      this.failedRequestTimestamp = this._getTimeStamp();
      this.sendRequest();
    }, FETCH_RETRY_DELAY);
  };

  sendRequest = () => {
    this.requestTimeStamps[this.retryAttemptedCount] = this._getTimeStamp();
    $.ajax(this.options)
      .done((json, statusText, xhr) =>
        this._handleSuccess({
          json,
          statusText,
          xhr,
          retriesAttempted: this.retryAttemptedCount,
          lastFailedRequestTimestamp: this.retryAttemptedCount
            ? this.requestTimeStamps[this.retryAttemptedCount - 1]
            : 'none'
        })
      )
      .fail(({ status: errorCode, responseText, statusText }) => {
        this._handleFailed({ errorCode, responseText, statusText });
      });
  };
}
