import get from 'lodash.get';
import { isBrowser } from 'acq-utils/lib/misc';
import { DTM_PAGE_LOAD } from 'src/services/types';
import DtmTaggingModel from './model';
import { dtmEvents } from './events';
import textRemoveTags from 'service_modules/utils/text-remove-tags';
import { ensurePageAttributesReady } from 'src/universal/utils/thirdPartyScriptHelpers/ensureAdobeHasLoaded';

const dtmTaggingModel = new DtmTaggingModel();

export { dtmTaggingModel };

export default dtmEvents;

// an expandable list.
const predefinedAnchors = ['#offer-terms', '#benefit-terms'];

const getUrlWithoutAnchor = (url = '', anchor) => {
  return anchor ? url.split(anchor)[0] : url;
};

const findAnchorInUrl = (url = '', possibleAnchors = []) => {
  return possibleAnchors.find(anchor => url.endsWith(anchor));
};

export function mapUrlWithDtmLinkQueryWithAnchor(
  url,
  dtmTag,
  anchorNames = predefinedAnchors
) {
  const urlLocal = typeof url === 'string' ? url : '';
  const anchorTxt = findAnchorInUrl(urlLocal, anchorNames);
  const urlWithoutAnchor = getUrlWithoutAnchor(urlLocal, anchorTxt);
  const resultUrl = dtmTaggingModel.appendLinkQuery(urlWithoutAnchor, dtmTag);
  return `${resultUrl}${anchorTxt ?? ''}`;
}

export function mapUrlWithDtmLinkQuery(url, dtmTag) {
  url = dtmTaggingModel.appendLinkQuery(url, dtmTag);
  return url;
}

export const sendDTMClickEvent = (dtmTag = {}) =>
  dtmEvents.dispatch({
    type: 'DTM_CLICK',
    dtmTag
  });

export const sendDTMEvents = (dtmList = []) =>
  dtmEvents.dispatch({
    type: 'DTM_EVENTS',
    dtmList
  });

export const sendDTMCustomEvent = async (dtmTag = {}, productInfo) => {
  if (typeof window === 'undefined') {
    return;
  }

  if (String(dtmTag.eventAction).toLowerCase() === 'impression') {
    /*
    US4857910
    we only need to wait for page attributes to be ready.
    */
    await ensurePageAttributesReady();
  }

  dtmEvents.dispatch({
    type: 'DTM_CUSTOM',
    dtmTag,
    productInfo
  });
};

export const setPageAttributes = (attrObj = {}) => {
  if (isBrowser()) {
    const attrNode = get(window.digitalData, 'page.attributes');
    if (attrNode) {
      Object.assign(attrNode, attrObj);
    }
  }
};

export const setAnalyticsCardMemberData = (
  isMemberLoggedIn = false,
  cardTitles = []
) => {
  // Per GACC-3800, this method should ONLY be called if the user is a card-member
  if (isBrowser()) {
    const userData = get(window, 'digitalData.user') || {};
    userData.loginStatus = !!isMemberLoggedIn;
    const arrTitles = cardTitles.map(title => textRemoveTags(title));
    userData.cardPortfolio = arrTitles.join(':');
  }
};

/*
why this method?
(1) recent stories (3868, 3905) require more than just firing the load event.
(2) this method is to be shared by all the pages so to reduce cluttering on the view module.
*/
export const invokePageLoadEvent = ({
  pznAttributes = {},
  productInfoList = [],
  viewDispatch = () => {}
}) => {
  if (!isBrowser() || !window.digitalData) {
    return;
  }

  setPageAttributes({
    pznId: pznAttributes.pznId,
    pznRequestId: pznAttributes.pznRequestId
  });

  dtmEvents.dispatch({
    type: DTM_PAGE_LOAD,
    data: { viewDispatch },
    payload: {
      eventInfo: {
        eventType: pznAttributes.eventType || 'pzn',
        eventAction: pznAttributes.eventAction || 'impression'
      },
      productInfo: productInfoList
    }
  });
};

export const invokeRicherOfferEvent = ({ eventAsset }) =>
  sendDTMCustomEvent({
    eventAction: 'impression',
    eventType: 'pageInteraction',
    eventAsset
  });
