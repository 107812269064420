'use strict';

import data from 'service_modules/static/json/card-names.json';

function getByKey(key) {
  return data && data[key];
}

export function getCardInfo(key) {
  let data = getByKey(key);
  if (typeof data === 'string') {
    data = getByKey(data);
    data.alias = true;
  }
  return data || {};
}
