import { SEGMENT_TYPES, getDcfSegment } from './dcf-segment-operations';
import {
  recordUserBannerDismissal,
  hasUserDismissedBanner
} from './banner-dismissal-track';

export {
  SEGMENT_TYPES,
  getDcfSegment,
  recordUserBannerDismissal,
  hasUserDismissedBanner
};
