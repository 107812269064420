import browserStorage from 'acq-utils/lib/BrowserStorage';
import { STORAGE } from 'acq-utils/lib/enums';
import { isServer } from 'src/universal/utils/browser-detection/index';
import { getCookieItem } from 'service_modules/utils/window-cookies';

export const DCF_SETTINGS = {
  COOKIE_KEY: 'aam_cs',
  SESSION_KEY: 'dcf-code',
  CHECK_INTERVAL: 100,
  COOKIE_TIMEOUT: 2000
};

export const getDcfCookie = () => {
  if (isServer()) {
    return Promise.resolve('');
  }

  const storage = browserStorage(STORAGE.session).bindItem(DCF_SETTINGS.SESSION_KEY);

  const savedVal = storage.get();

  if (savedVal) {
    return Promise.resolve(savedVal);
  }

  return new Promise(resolve => {
    const dtStart = new Date();
    let cookieVal;
    let intervalId;
    const endService = resultVal => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }

      resultVal && storage.set(resultVal);

      return resolve(resultVal);
    };

    cookieVal = getCookieItem(DCF_SETTINGS.COOKIE_KEY);
    if (cookieVal) {
      return endService(cookieVal);
    }

    intervalId = window.setInterval(() => {
      cookieVal = getCookieItem(DCF_SETTINGS.COOKIE_KEY);
      if (cookieVal) {
        return endService(cookieVal);
      }

      if (new Date().getTime() - dtStart.getTime() >= DCF_SETTINGS.COOKIE_TIMEOUT) {
        return endService(undefined);
      }
    }, DCF_SETTINGS.CHECK_INTERVAL);
  });
};
