'use strict';

import { createStore as createEvents } from 'redux';

import { dtmTaggingModel } from 'service_modules/models/dtm-tagging';
import { isServer } from 'src/universal/utils/browser-detection/index';

const ACTION_TYPES = {
  DTM_CLICK: function(action) {
    dtmTaggingModel.triggerDTMEvent(action);
  },
  DTM_EVENTS: (action = {}) =>
    dtmTaggingModel.triggerMultipleDTMEvents(action.dtmList),
  DTM_CUSTOM: function(action) {
    dtmTaggingModel.triggerDTMEvent(action, true);
  },
  DTM_PAGE_LOAD: function(action) {
    dtmTaggingModel.triggerDTMPageLoad({
      legacyTag: action.dtmTag,
      dtmTag: action.payload
    });
    if (action.data && typeof action.data.viewDispatch === 'function') {
      action.data.viewDispatch({
        type: 'CURRENT_DTM_EVENT',
        data: { dtmEventName: action.type }
      });
    }
  },
  DTM_DATA_READY: function(action) {
    dtmTaggingModel.updateDTMPageData(action);
  }
};

export const dtmEvents = createEvents(function(state, action) {
  if (isServer()) {
    return;
  }

  if (typeof ACTION_TYPES[action.type] === 'function') {
    ACTION_TYPES[action.type].call(null, action);
  }
});
