'use strict';

const ExternalScrollToElement = require('scroll-into-view');
import { isServer } from 'src/universal/utils/browser-detection/index';
import {
  getWindowObject,
  getDocumentObject
} from 'src/universal/utils/window-document/index';

const _windowObj = getWindowObject();
const _documentObj = getDocumentObject();

function wrapElement(elem) {
  if (!isServer() && typeof elem === 'string') {
    return _documentObj.querySelector(elem);
  }
  return elem;
}

function scrollToAnimated(element, to, duration) {
  if (isServer() || duration <= 0) {
    return;
  }

  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;
  _windowObj.setTimeout(function() {
    element.scrollTop = element.scrollTop + perTick;
    if (element.scrollTop == to) {
      return;
    }
    scrollToAnimated(element, to, duration - 10);
  }, 10);
}

export function scrollToDocTop(duration) {
  if (isServer()) {
    return;
  }

  scrollToAnimated(_documentObj.body, 0, duration || 600);
}

/*
 *
 * ARGS:
 * <options>: {
 *   name: <string>
 *   topPos: <number>
 *   marker: <number>
 *   eventType: <string>
 *   element: <element>
 * }
 */

const noop = () => {};

export const scrollToElementTween =
  typeof window === 'undefined'
    ? noop
    : (function() {
        return function scrollToElementTween(element, options = {}) {
          element = wrapElement(element);

          return new Promise(function(resolve, reject) {
            if (!element) {
              return reject();
            }

            return ExternalScrollToElement(element, options, () => {
              resolve();
            });
          });
        };
      })();
