/* eslint-disable no-unused-vars */

import camelCaseToDash from 'core_modules/utils/camelCaseToDash';

export default ({ cardShop: data, cardList = [] }) => {
  const dataCopy = { ...data };
  dataCopy.pageData = dataCopy.page;
  dataCopy.globalData = dataCopy.global;

  delete dataCopy.page;
  delete dataCopy.global;

  if (dataCopy.pageData.ratesAndFeesLabel) {
    dataCopy.pageData['rates&FeesLabel'] = dataCopy.pageData.ratesAndFeesLabel;
  }

  if (dataCopy.pageData.openFAQ) {
    dataCopy.pageData.openFAQ.description =
      dataCopy.pageData.openFAQ.descriptionArray;

    dataCopy.pageData.openFAQ.content.map(item => {
      item.header = item.headerBlock;

      if (item.descriptionBlock) {
        item.descriptionBlock.map(block => {
          if (block && !block.description && block.descriptionArray) {
            block.description = block.descriptionArray;

            if (Array.isArray(block.descriptionList)) {
              block.description = block.description.concat(block.descriptionList);
            }
          }

          if (
            block &&
            typeof block.description === 'string' &&
            Array.isArray(block.descriptionList)
          ) {
            const updatedList = block.descriptionList.map(item => `- ${item}`);
            block.description = [block.description].concat(updatedList);
          }
          return block;
        });
        item.description = item.descriptionBlock;
      }
    });
  }

  if (dataCopy.pageData.benefitTerms && dataCopy.pageData.benefitTerms.content) {
    dataCopy.pageData.benefitTerms.content.map(item => {
      if (item && !item.description && item.descriptionArray) {
        item.description = item.descriptionArray;
      }
      return item;
    });
  }

  if (cardList && dataCopy.pageData.dtmPageName) {
    const { metaData } = { ...dataCopy.pageData.pageInfo };

    Object.keys(metaData).map(key => {
      const newMetaDataKey = camelCaseToDash({ str: key });
      dataCopy.pageData.pageInfo.metaData[newMetaDataKey] = metaData[key];
    });

    cardList.map(card => {
      card.features = card.cardFeatures;

      if (card.cardFeatures.descriptionBlock) {
        let temp = [];
        for (let key in card.cardFeatures.descriptionBlock) {
          if (Array.isArray(card.cardFeatures.descriptionBlock[key])) {
            card.cardFeatures.descriptionBlock[key].map(v => temp.push(v));
          } else {
            temp.push(card.cardFeatures.descriptionBlock[key]);
          }
        }
        card.cardFeatures.descriptionList = temp;
      }

      return card;
    });
  }
  return dataCopy;
};
