import { isServer } from 'src/universal/utils/browser-detection/index';
import { getDcfCookie } from './dcf-detection';

export const SEGMENT_TYPES = {
  LARGE_BUSINESS: 'LB',
  SMALL_BUSINESS: 'SB',
  NO_CLASSIFICATION: 'NC'
};

const SEGMENTS_HASH = {
  '6474755': SEGMENT_TYPES.LARGE_BUSINESS,
  '6474750': SEGMENT_TYPES.SMALL_BUSINESS
};

export const getDcfCodeByRawCookie = rawCookie => {
  if (isServer() || !rawCookie) {
    return SEGMENT_TYPES.NO_CLASSIFICATION;
  }

  const decodedCookie = decodeURIComponent(String(rawCookie));
  const dcfValues = decodedCookie.split(',');
  let isValidCookie = [];
  for (let i = 0; i < dcfValues.length; i++) {
    let dcfValue;
    [, dcfValue] = dcfValues[i].split('=');
    const segType = dcfValue
      ? SEGMENTS_HASH[String(dcfValue)]
      : SEGMENT_TYPES.NO_CLASSIFICATION;
    isValidCookie.push(segType);
  }
  let largeBusinessCookie = isValidCookie.includes(SEGMENT_TYPES.LARGE_BUSINESS);
  let smallBusinessCookie = isValidCookie.includes(SEGMENT_TYPES.SMALL_BUSINESS);

  if (largeBusinessCookie && smallBusinessCookie) {
    return SEGMENT_TYPES.NO_CLASSIFICATION;
  } else if (largeBusinessCookie) {
    return SEGMENT_TYPES.LARGE_BUSINESS;
  } else if (smallBusinessCookie) {
    return SEGMENT_TYPES.SMALL_BUSINESS;
  } else {
    return SEGMENT_TYPES.NO_CLASSIFICATION;
  }
};

export const getDcfSegment = () => {
  if (isServer()) {
    return Promise.resolve(SEGMENT_TYPES.NO_CLASSIFICATION);
  }
  return new Promise(resolve => {
    getDcfCookie().then(result => {
      return resolve(getDcfCodeByRawCookie(result));
    });
  });
};
