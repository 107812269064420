'use strict';

import browserStorage from 'acq-utils/lib/BrowserStorage';
import { STORAGE } from 'acq-utils/lib/enums';

export default {
  activity: browserStorage(STORAGE.local).bindItem('user-interaction'),

  selected: (() => {
    const storage = browserStorage(STORAGE.local).bindItem('selected');

    return {
      get: () => {
        const value = storage.get();
        return Array.isArray(value) ? value : [];
      },
      set: arr => {
        if (arr !== undefined) {
          storage.set(Array.isArray(arr) ? arr : [arr]);
        }
      }
    };
  })()
};
