import URL from 'url';
import { appendQueryParams } from 'acq-utils/lib/utils';
import { DEFAULT_CONFIG } from 'src/fetch-default-config';
import { isServer as isServerUtil } from 'src/universal/utils/browser-detection/index';

export const isServer = isServerUtil();
export const isClient = !isServer;

export const setHeaders = ({ headers = {}, url = '', cookie = '', contentType }) => {
  headers.host = URL.parse(url).host;
  headers['Cookie'] = isServer ? cookie : document.cookie;
  headers['x-performance-inav-unique-id'] = isServer
    ? global.uuid
    : window.page_load_time.performanceINavUniqueId;

  headers['X-XSS-Protection'] = 1; // security Vulnerabilities
  headers['X-Frame-Options'] = 'SAMEORIGIN'; // security Vulnerabilities
  headers['Strict-Transport-Security'] = `max-age=31536000; includeSubDomains`; // security Vulnerabilities

  if (contentType) {
    headers['Content-Type'] = contentType;
    headers['X-Content-Type-Options'] = 'nosniff'; // security Vulnerabilities
  }

  return headers;
};

export const validateTimeout = ({ timeout, method = 'GET' }) => {
  if (
    !(
      typeof timeout === 'number' &&
      isFinite(timeout) &&
      Math.floor(timeout) === timeout
    )
  ) {
    if (typeof timeout === 'undefined') {
      console.error(
        [
          `\nERROR: AJAX ${method} 'options.timeout' was set to an invalid value: ${timeout}.`,
          `Instead timeout is now set to: ${DEFAULT_CONFIG.timeout}`
        ].join('\n')
      );
    }
    timeout = DEFAULT_CONFIG.timeout;
    return timeout;
  }

  return timeout;
};

export const appendCorrelationIdToUrl = (url, correlationId) =>
  appendQueryParams(url, !correlationId ? {} : { cid: correlationId });
