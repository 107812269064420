'use strict';

import get from 'lodash.get';
import queryString from 'query-string';
import ajax from 'service_modules/xml-http-requests/card-shop-api-v1.ajax';
import { getStateObj } from 'src/universal/utils/reduxStoreUtil';
import { determineCmIndicator } from 'src/universal/components/PZN/FetchData/getPznParams';
import {
  getVisitSearchEngine,
  getReferringUrl,
  getNavigatorProps
} from 'service_modules/pzn-params';

function getPZNEncrypted(params = {}) {
  const baseUrl = `open/content/og/${params.pageId}/${params.EEP}/${
    params.cardShortName
  }`;

  const stateObj = getStateObj();
  const paramList = [];

  if (params.pmcCode) {
    paramList.push(`pmcCode=${params.pmcCode}`);
  }

  const needCmIndicator = determineCmIndicator(stateObj.cardMemberInfo);

  if (needCmIndicator) {
    paramList.push('isCM=true');
  }

  const extraParamsHash = {
    resolution: `${window.clientWidth || 0}X${window.clientHeight || 0}`,
    newBrowserArea: `${window.screen.width || 0}X${window.screen.height || 0}`,
    visitSearchEngine: encodeURIComponent(getVisitSearchEngine()),
    referringUrl: encodeURIComponent(getReferringUrl()),
    ...getNavigatorProps()
  };
  const extraParams = queryString.stringify(extraParamsHash);
  const url = `${baseUrl}?${paramList.join('&')}&${extraParams}&pznReqFlowId=${get(
    stateObj,
    'pznData.pznAttributes.pznReqFlowId'
  )}&recoveryEep=${get(stateObj, 'pznData.eepUsedForPznRequest')}`;
  return ajax.get(url, {
    'set-cookie': params['set-cookie']
  });
}

export { getPZNEncrypted };
