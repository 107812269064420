import { getPage } from 'page_modules/active-page';

const PAGE_TYPES = {
  home: 'home',
  cardComparison: 'cardComparison',
  cardDetails: 'cardDetails',
  openCategory: 'openCategory',
  corpCategory: 'corpCategory',
  corporateCategory: 'corporateCategory',
  ukVACBusiness: 'ukVACBusiness',
  ukVACCorporate: 'ukVACCorporate',
  cardComparisonOverlayBusiness: 'cardComparisonOverlayBusiness',
  cardComparisonOverlayCorporate: 'cardComparisonOverlayCorporate'
};

function getPageTypeByRequestUrl(reqUrl) {
  if (!reqUrl || typeof reqUrl !== 'string') {
    return '';
  }

  const pageTypeMap = {
    '/open/content/bhp': PAGE_TYPES.home,
    '/open/content/bcc': PAGE_TYPES.cardComparison,
    '/open/content/bcd/': PAGE_TYPES.cardDetails,
    '/open/content/oc': PAGE_TYPES.openCategory,
    '/open/content/bc': PAGE_TYPES.openCategory,
    '/open/content/cc': PAGE_TYPES.corporateCategory,
    '/intl_gcp/uk/cards/business': PAGE_TYPES.ukVACBusiness,
    '/intl_gcp/uk/cards/corporate': PAGE_TYPES.ukVACCorporate,
    'intl_gcp/uk/compare-cards/business': PAGE_TYPES.cardComparisonOverlayBusiness,
    'intl_gcp/uk/compare-cards/corporate': PAGE_TYPES.cardComparisonOverlayCorporate,
    '/open/us/cards/corporate/': PAGE_TYPES.corporateCategory,
    '/open/us/cards': PAGE_TYPES.cardDetails,
    '/open/content/v2/us/business/home': 'businessHome',
    '/open/content/v2/us/business/platinum': 'businessPDP',
    '/open/content/v2/us/business/gold': 'businessPDP',
    '/open/content/v2/us/business/blue-business-cash': 'businessPDP',
    '/open/content/v2/us/business/blue-business-plus': 'businessPDP',
    '/open/content/v2/us/business/plum': 'businessPDP',
    '/open/content/v2/us/business/green': 'businessPDP',
    '/open/content/v2/us/business/amazon-business-prime': 'businessPDP',
    '/open/content/v2/us/business/amazon-business': 'businessPDP',
    '/open/content/v2/us/business/platinum-delta-skymiles': 'businessPDP',
    '/open/content/v2/us/business/gold-delta-skymiles': 'businessPDP',
    '/open/content/v2/us/business/delta-reserve': 'businessPDP',
    '/open/content/v2/us/business/hilton-honors': 'businessPDP',
    '/open/content/v2/us/business/starwood-preferred-guest': 'businessPDP',
    '/open/content/v2/us/business/lowes-business-rewards': 'businessPDP',
    '/open/content/v2/us/business/compare': 'compareCards',
    '/open/content/v2/us/corporate/category': 'corpCategory',
    '/open/content/v2/us/corporate/corporate-green': 'corpPDP',
    '/open/content/v2/us/corporate/corporate-gold': 'corpPDP',
    '/open/content/v2/us/corporate/corporate-platinum': 'corpPDP',
    '/open/content/v2/us/business/vac': PAGE_TYPES.openCategory
  };

  const urlLocal = String(reqUrl).toLowerCase();
  const keys = Object.keys(pageTypeMap);

  for (let i = 0; i < keys.length; i++) {
    if (urlLocal.includes(keys[i])) {
      return pageTypeMap[keys[i]];
    }
  }

  return '';
}

const getPageTypeByGraphQLQuery = (query = '') => {
  if (typeof query !== 'string') {
    return '';
  }

  switch (query) {
    case 'VAC_BUSINESS_QUERY':
      return PAGE_TYPES.openCategory;
    case 'PDP_BUSINESS_QUERY':
      return PAGE_TYPES.cardDetails;
    case 'HOME_QUERY':
      return PAGE_TYPES.home;
    case 'CORPORATE_CATEGORY_QUERY':
      return PAGE_TYPES.corpCategory;
    default:
      return '';
  }
};

export function setCurrentPageType(data, url) {
  if (data && data.global && url) {
    data.global['currentPageType'] = getPageTypeByRequestUrl(url);
  } else if (data && data.globalData && url) {
    data.globalData['currentPageType'] = getPageTypeByRequestUrl(url);
  }
  return data;
}

export const setCurrentPageTypeGraphQL = (data, query = '') => {
  const globalData = data && (data.globalData || data.global);
  if (globalData) {
    globalData.currentPageType = getPageTypeByGraphQLQuery(query) || getPage();
  }
  return data;
};
